.zoom_overlay {
  position: "fixed";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: "rgba(0, 0, 0, 0.75)";
}

.zoom_content {
  position: "absolute";
  top: "200px";
  left: "180px";
  right: "180px";
  height: "380px";
  bottom: "120px";
  border: "1px solid #ccc";
  background: "#fff";
  overflow: "auto";
  -webkit-overflow-scrolling: "touch";
  border-radius: "10px";
  outline: "none";
  padding: "20px";
}

.uploadDocs_overlay {
  position: "fixed";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: "rgba(0, 0, 0, 0.75)";
}

.uploadDocs_content {
  position: "absolute";
  top: "200px";
  left: "280px";
  right: "280px";
  bottom: "70px";
  width: "390px";
  border: "1px solid #ccc";
  background: "#fff";
  overflow: "auto";
  -webkit-overflow-scrolling: "touch";
  border-radius: "10px";
  outline: "none";
  padding: "20px";
}
