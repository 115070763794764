/* styles.css */
.dropdown {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.8s ease-in-out, max-height 0.8s ease-in-out;
}

.dropdown.fadeIn {
  opacity: 1;
  max-height: 500px;
  overflow-y: scroll;
}
