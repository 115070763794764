.responsive-container {
  position: relative;
  width: 100%;
  padding-bottom: 77.32%; /* Aspect ratio (750 / 970) * 100 */
  height: 0;
  overflow: hidden;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 77.32%;
  border: 0;
}
