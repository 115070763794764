.react-multi-carousel-track {
  transition: transform 1.5s ease-in-out;
}
.react-multiple-carousel__arrow {
  background: none; /* Remove background color */
  border: none; /* Remove border */
  color: #d33479; /* Change the icon color */
  font-size: 24px; /* Adjust icon size if needed */
  outline: none; /* Remove outline */
  transition: color 0.3s ease;
  font-weight: 600;
  z-index: 1;
}
.react-multiple-carousel__arrow::before {
  font-size: 28px;
  color: #d33479; /* Change the icon color */
  display: block;
  font-family: revicons;
  font-weight: 600;
  text-align: center;
  z-index: 2;
  position: relative;
}
.react-multiple-carousel__arrow::after {
  color: #ffffff; /* Change the icon color */
}
.react-multiple-carousel__arrow:hover {
  background: none;
  color: white;
}
