@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.menu-inactive {
  @apply cursor-pointer font-semibold flex text-primary gap-4 rounded-xl bg-white hover:bg-lingawa-primary hover:text-white p-4 text-sm mb-2;
}

.menu-active {
  @apply cursor-pointer flex gap-4 font-semibold rounded-xl bg-lingawa-primary text-white p-4 text-sm mb-2;
}

.parent-menu-inactive {
  @apply cursor-pointer justify-between font-semibold flex text-primary gap-4 rounded-xl bg-white hover:bg-lingawa-primary hover:text-white p-4 text-sm mb-2;
}

.parent-menu-active {
  @apply cursor-pointer justify-between flex gap-4 font-semibold rounded-xl bg-lingawa-primary text-white p-4 text-sm mb-2;
}

.submenu-inactive {
  @apply cursor-pointer hover:text-secondary font-semibold text-primary px-12 pt-2 text-sm mb-4;
}

.submenu-active {
  @apply cursor-pointer text-secondary font-semibold px-12 text-sm pt-2 mb-4;
}

.pink-button {
  @apply bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-xl
  px-7 py-3;
}
.pink-button-landing {
  @apply bg-secondary w-[250px] font-semibold hover:bg-secondary-light hover:shadow-xl text-white rounded-xl
  px-7 py-4;
}

.gold-button {
  @apply bg-[#F3D671]  hover:shadow-xl text-primary text-sm rounded-xl font-semibold
  px-3 py-2;
}
.rotate-10 {
  transform: rotate(-2.7deg);
}

.pink-outline-button {
  @apply bg-white hover:bg-secondary border-2 border-secondary hover:shadow-xl text-secondary hover:text-white rounded-2xl
  px-7 py-3 z-10;
}

.purple-button {
  @apply bg-lingawa-primary  hover:bg-primary-light hover:shadow-xl text-white rounded-2xl
  px-7 py-3 z-10;
}

.purple-outline-button {
  @apply bg-white hover:bg-lingawa-primary border-2 border-lingawa-primary hover:shadow-xl text-lingawa-primary font-semibold hover:text-white rounded-xl
  px-7 py-2.5 z-10;
}

.purple-button-no-shadow {
  @apply bg-lingawa-primary hover:bg-primary-light font-semibold text-white rounded-xl
  px-7 py-3 z-10;
}

.pink-button-no-shadow {
  @apply bg-secondary hover:bg-secondary-light font-semibold text-white rounded-xl
  px-7 py-3 z-10;
}
.loader {
  @apply border-2 border-gray-200 border-t-2 border-t-transparent rounded-full w-4 h-4 animate-spin mr-2;
}

.blue-button {
  @apply bg-blue-500 hover:bg-blue-600 hover:shadow-xl text-white rounded-2xl
  px-7 py-3;
}

.gray-button {
  @apply bg-gray-200  text-white rounded-2xl
  px-7 py-3;
}
.gray-button-2 {
  @apply bg-gray-200 font-semibold text-primary rounded-2xl
  px-4 py-2;
}

.orange-button {
  @apply bg-orange-500 hover:bg-orange-600 hover:shadow-xl text-white rounded-2xl
  px-7 py-3;
}

.disabled-button {
  @apply bg-gray-200 text-white rounded-xl px-7 py-3;
}

.element-title {
  @apply mb-2 text-gray-purple font-medium;
}

.no-focus-outline:focus {
  outline: none;
}

.filter-button-active {
  @apply bg-primary text-sm font-medium text-white uppercase px-3 py-1 rounded-lg;
}

.filter-button-inactive {
  @apply bg-gray-50 text-sm font-medium hover:text-primary text-light-purple uppercase px-3 py-1 rounded-lg;
}

.white-card-rounded {
  @apply shadow-md border-[1px] border-gray-200 rounded-2xl bg-white;
}

.pink-card-rounded {
  @apply shadow-md border-[1px] border-secondary rounded-2xl bg-pink-300;
}
.white-card-more-rounded {
  @apply shadow-md border-[1px]  rounded-3xl bg-white;
}
.gray-card-rounded {
  @apply shadow-md border-[1px] rounded-3xl bg-pale-purple-bg;
}
.inactive-mobile-footer-menu {
  @apply flex flex-col items-center justify-center text-sm border-x-2 border-x-gray-200 py-4 px-8 text-light-purple;
}

.active-mobile-footer-menu {
  @apply flex flex-col items-center justify-center text-sm border-t-4 border-t-secondary border-x-2 border-x-gray-200 py-3 px-8 text-secondary;
}
.active-tab-menu {
  @apply text-primary font-semibold  text-lg md:text-xl border-b-[4px] border-secondary p-2;
}
.inactive-tab-menu {
  @apply text-primary text-lg md:text-xl border-b-[1px] border-pale-purple-bg p-2;
}
.toggle-checkbox {
  @apply appearance-none h-6 w-6 border border-gray-300 rounded-md bg-white focus:outline-none transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer;
}

.toggle-checkbox:checked {
  @apply bg-lingawa-orange border-white;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/8/8f/Checkmark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.weekday-day {
  @apply text-primary font-medium;
}

.date-nav {
  @apply hover:bg-pale-purple-bg cursor-pointer flex items-center justify-center border-y-2 p-1;
}

.weekday-date {
  @apply text-primary font-semibold text-xs;
}

.indicator {
  @apply border-l-[1px] border-t-gray-200 flex flex-col items-center justify-center py-2.5;
}

.indicator-mobile {
  @apply border-l-[1px] border-t-gray-200 px-2.5 flex flex-col items-center justify-end py-2.5;
}

.time-slot {
  @apply flex flex-col items-center justify-start py-2.5;
}

.time-slot-mobile {
  @apply flex flex-col items-center justify-start py-1;
}

.calendar-weekdays {
  @apply border-t-4 border-secondary flex flex-col items-center justify-center py-2;
}

.draggable-word-sentenceArea {
  @apply bg-pale-purple-bg px-2 py-1 mt-2 mx-1 max-h-8 text-primary rounded-md;
}

.draggable-word-wordbank {
  @apply bg-white text-primary px-2 py-1 mt-2 mx-1 max-h-8 rounded-md;
}

.sentence-area {
  @apply border-2 border-gray-200 rounded-md p-4;
}

.perspective {
  perspective: 1000px;
}

.flashcard {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
  width: 300px;
  height: 200px;
}

.flashcard.flipped {
  transform: rotateY(180deg);
}

.large_flashcard {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
  width: 100%;
  height: 400px;
}

.large_flashcard.flipped {
  transform: rotateY(180deg);
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.back {
  transform: rotateY(180deg);
}
.gray-card {
  @apply bg-gray-100 p-4 rounded-2xl border-gray-200 border;
}
.starburst {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  background-color: #e9d5ff; /* Adjust color as needed */
  width: 100%;
  height: 100%;
}

.rotate-21 {
  transform: rotate(-21deg);
}
.bg_url {
  background-image: url("https://cdn.topset.app/star_bg.png");
}
#cc-main {
  /** Change font **/
  --cc-font-family: Monterrat, sans-serif;

  --cc-primary-color: #340d24;
  --cc-secondary-color: #711a4c;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #711a4c;
  --cc-btn-primary-border-color: #711a4c;
  --cc-btn-primary-hover-bg: #e84c18;
  --cc-btn-primary-hover-border-color: #e84c18;

  --cc-btn-secondary-bg: #e84c18;
  --cc-btn-secondary-color: #fff;
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #d4dae0;
  --cc-btn-secondary-hover-color: #711a4c;
  --cc-btn-secondary-hover-border-color: #d4dae0;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: #e84c18;

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 10px;
}
