.form-fieldset {
  padding: 1rem;
  border-radius: 13px;
  border: 1px solid #333;
}

.form-fieldset + .form-fieldset {
  margin-top: 1rem;
}

.form-field {
  appearance: none;
  box-sizing: border-box;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 42px;
  margin: 0.5rem 0;
  padding: 0.5rem 0.75rem;
  color: #fff;
  border: 1px solid #222;
  background-color: #222;
  border-radius: 13px;
  outline: none;
}

.form-field::placeholder {
  color: #666;
}

.form-field:focus,
.form-field.rc-card-field--focused {
  border-color: royalblue;
}

.form-field:invalid,
.form-field.rc-card-field--invalid {
  border-color: red;
}

.form-field:required:valid,
.form-field.rc-card-field--completed {
  border-color: lime;
}

.form-field-error-message {
  color: red;
}

/** 
   * How to style autofilled fields:
   * @see https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ 
   */
.form-field:-webkit-autofill,
.form-field.rc-card-field--autofilled {
  border-color: yellow;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #222 inset;
  transition: background-color 5000s ease-in-out 0s;
}
